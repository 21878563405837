import {
  ApiResponseOfContractDto,
  ApiResponseOfIEnumerableOfAttachmentContractGroupDto,
  ApiResponseOfIEnumerableOfContractDto,
  ApiSortDirection,
  FileResponse,
  IContractApiService,
  OneTimeAttachmentInfoDto
} from '@api/api.service';
import { NEVER, Observable, of } from 'rxjs';

export class ContractApiMockService implements IContractApiService {
  getContracts(): Observable<ApiResponseOfIEnumerableOfContractDto> {
    return of({
      success: true,
      data: [],
      maxCount: 0
    });
  }

  getContractByContractId(contractId: string): Observable<ApiResponseOfContractDto> {
    return of({
      success: true,
      data: null
    });
  }

  getOneTimeAttachmentId(attachmentId: string): Observable<OneTimeAttachmentInfoDto> {
    return of({
      oneTimeId: attachmentId,
      validUntil: '2099-12-31T23:59:59Z'
    });
  }

  getContractAttachments(contractId: string, skip?: number, take?: number,
                         sortBy?: string, sortDirection?: ApiSortDirection)
    : Observable<ApiResponseOfIEnumerableOfAttachmentContractGroupDto> {
    return of({
      success: true,
      data: [],
      maxCount: 0
    });
  }

  getContractActionAttachments(contractId: string, skip?: number, take?: number,
                               sortBy?: string, sortDirection?: ApiSortDirection)
    : Observable<ApiResponseOfIEnumerableOfAttachmentContractGroupDto> {
    return of({
      success: true,
      data: [],
      maxCount: 0
    });
  }

  getAttachment(onetimeAttachmentId: string): Observable<FileResponse> {
    return NEVER;
  }
}
